.site-forms {
  background-color: #fff;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 28px 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 4px;

  &_with-files {
    background-image: linear-gradient(to left, #f8f8f8 192px, transparent 192px);
  }

  .ant-divider-horizontal {
    margin-top: 32px;
  }

  &__checkbox-wrapper {
    position: relative;
  }

  &__visibility-checkbox {
    margin-top: 2px;
    background-color: var(--color-gray3);
    width: 100%;
    padding: 10px 15px;
    user-select: none;
    font-size: 16px;
    border-radius: 4px;

    span:last-of-type {
      font-family: "Gilroy", "Arial", sans-serif;
      font-weight: 600;
    }
  }

  &__forms {
    max-width: 560px;
    width: 100%;
  }

  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 20px;
    line-height: 19px;
    font-weight: 800;
    color: #000000;
    margin: 0 0 12px 0;

    &_no-margin {
      margin: 0;
    }
  }

  &__text {
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 20px;
  }

  &__add-owner {
    width: 100%;
  }

  &__files-row {
    height: 143px;

    .site-form__files {
      width: 100%;
    }

    .file-form__items {
      width: 462px;
      display: flex;

      .site-form__dragger {
        margin-right: 15px;
        margin-bottom: 0;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  &__files-divider {
    margin-top: 12px !important;
    margin-bottom: 16px !important;
  }

  &__modal-row {
    display: flex;
    justify-content: space-between;
  }

  &__modal-wrapper {
    max-width: 368px;
  }
}

.site-form {
  $root: &;

  &__error {
    color: var(--color-red);
    font-size: 12px;
    line-height: 14px;
  }

  &__inputs-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 165px);
    grid-gap: 7px 16px;
  }

  &__input-with-suffix {
    position: relative;

    &_without-label {
      #{$root}__input-suffix {
        top: 11px;
        bottom: auto;
      }
    }
  }

  &__input-suffix {
    position: absolute;
    bottom: 11px;
    right: 11px;
    width: 18px;
    height: 18px;
    background-repeat: no-repeat;
    background-size: contain;

    &_icon_cross {
      background-image: url(./images/cross-icon.svg);
    }

    &_icon_check {
      background-image: url(./images/check-icon.svg);
    }
  }

  &__files-title {
    font-family: "Gilroy", "Arial", sans-serif;
    color: #000000;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;
    margin: 4px 0 15px 0;
  }

  &__files {
    width: 144px;
  }

  &__dragger {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__radio {
    margin: 2px 0;

    &_gender {
      #{$root}__radio-title {
        margin-bottom: 11px;

        line-height: 17px;
      }
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    &_no-margin {
      margin: 0;
    }
  }

  &__radio-title {
    display: block;
    color: #000000;
    font-size: 13px;
    margin-bottom: 2px;
  }

  &__title {
    font-family: "Gilroy", "Arial", sans-serif;
    font-size: 20px;
    line-height: 19px;
    font-weight: 800;
    color: #000000;
    margin: 0 0 12px 0;

    &_no-margin {
      margin: 0;
    }
  }

  &__subtitle {
    margin: 0 0 16px;

    font-family: "Gilroy", "Arial", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  &__address-input {
    input {
      padding-left: 22px !important;
      padding-right: 26px !important;
      background: left center / 18px auto no-repeat url(./images/pin-icon.svg) !important;
    }

    .ant-select-selection-placeholder {
      padding-left: 22px !important;
      padding-right: 11px;
      color: #bfbfbf;
      opacity: 1;
    }

    .ant-select-selection-search-input {
      display: flex;
      align-items: center;
    }

    .ant-input-prefix {
      width: 18px;
      height: 18px;

      svg {
        display: block;

        width: 100%;
        height: auto;
      }
    }
  }

  .ant-upload-text {
    color: #262626 !important;
    font-size: 14px !important;
  }

  .ant-upload-hint {
    color: #8c8c8c !important;
    font-size: 12px !important;
  }
}
